import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, FormGroup, Row } from 'reactstrap'
import { useSelector } from 'react-redux';
import "../../../assets/scss/plugins/extensions/toastr.scss"
import "react-toggle/style.css"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import API from '../../../providers/api';
import moment from 'moment';

export const ModalPremium = ({ showModal, setShowModal }) => {
    const user_id = useSelector(state => state.auth.login.state.data.user.verifyLogin.id);
    const [img, setImg] = useState('')
    const [img2, setImg2] = useState('')
    const [goal, setGoal] = useState('')

    const toggleModal = () => {
    setShowModal(!showModal)
    }

    useEffect(() => {
        searchGoalPremiumYear()
    }, [])

const searchGoalPremiumYear = () => {
    API.searchGoalPremiumYear(moment().format('YYYY'))
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
            setImg(dataJson.data[0].imagegoal)
            setImg2(dataJson.data2[0].imagegoal)
            setGoal(dataJson.data2[0].goal)
        }
    })
    .catch((e) => {
        console.log("Error =>", e)
    })
}

const toggleClose = () => setShowModal(!showModal)

return (
<Modal size='lg' isOpen={showModal} toggle={toggleClose}>
    <ModalHeader toggle={toggleClose}>
    Premium
    </ModalHeader>
    <ModalBody>
        <Row>
            <Col className='text-center'>
                <h2>First place in sales month, Let's GO!</h2>,
            </Col>
        </Row>
        <Row>
            <Col md="12" >
                <img src={img} style={{width:'100%'}} />
            </Col>
        </Row>
        <Row>
            <Col className='text-center'>
                <h2>This could be yours if you achieve the proposed goal of <b style={{color:'blue'}}>{goal}</b> Let's GO!</h2>,
            </Col>
        </Row>
        <Row>
            <Col md="12" >
                <img src={img2} style={{width:'100%'}} />
            </Col>
        </Row>
    </ModalBody>
    <ModalFooter>
        <Button outline color="success" onClick={toggleModal}>Cerrar</Button>{" "}
    </ModalFooter>
</Modal>
)
}
