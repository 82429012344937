//export const HOST = 'https://apimv.ticservice.org:17100/';
export const HOST = process.env.REACT_APP_API_URL;
export const VERSION = 'api/v1/';

const APP_URL = {
    // AUTH
    LOGIN: `${HOST}${VERSION}auth/login/`,

    LISTSIGNATURE: `${HOST}${VERSION}admin/form/listSignature`,
    LISTAGENCYCMS: `${HOST}${VERSION}admin/form/listAgencyCMS`,
    EXPORTDATACMS: `${HOST}${VERSION}admin/form/exportDataCMS`,
    DELETESIGNATURECMS: `${HOST}${VERSION}admin/form/deleteSignatureCMS`,
    CREATEAGENT: `${HOST}${VERSION}admin/form/createAgent`,
    SEARCHLOGSIGNATUREDELETE: `${HOST}${VERSION}admin/form/searchLogSignatureDelete`,
    CREATECMS: `${HOST}${VERSION}admin/form/createCMS`,
    LISTCMS: `${HOST}${VERSION}admin/form/listCMS`,
    LISTCMSSIGNATURE: `${HOST}${VERSION}admin/form/listCMSSignature`,
    TOTALSIGNMONTH: `${HOST}${VERSION}admin/form/totalSignMonth`,
    SENDCONTACT: `${HOST}${VERSION}admin/form/sendContact`,
    REPORTSFILTER: `${HOST}${VERSION}admin/form/reportsFilter`,
    UPDATEINFOUSER: `${HOST}${VERSION}admin/users/updateinfouser`,
    UPDATEPASSUSER: `${HOST}${VERSION}admin/users/updatePassUser`,

    // ADMIN - APPOINTMENT
    SEARCH_PATIENTS_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/searchPatient`,
    UPLOAD_EVIDENCES: `${HOST}${VERSION}admin/appointment/uploadFiles`,
    CREATE_APPOINTMENT: `${HOST}${VERSION}admin/appointment/create`,
    LIST_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/list`,
    CHANGE_STATUS_APPOINTMENT: `${HOST}${VERSION}admin/appointment/updateStatus`,
    LIST_FILE_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/listFiles`,
    LIST_APP_STATUS_USER: `${HOST}${VERSION}admin/appointment/listAppStatusUser`,
    COUNT_APPOINTMENT_TOTAL_DAY_MONTH: `${HOST}${VERSION}admin/appointment/CountAppointmentTotalDayMonth`,
    COUNT_APP_STATUS: `${HOST}${VERSION}admin/appointment/countAppStatusUser`,
    LIST_TRACKER: `${HOST}${VERSION}admin/appointment/listTracker`,

    // ADMIN - APPOINTMENT - REFFERED
    CREATE_REFFERED: `${HOST}${VERSION}admin/appointment/createReffered`,
    LIST_REFFERED_ALL: `${HOST}${VERSION}admin/appointment/listRefferedAll`,
    LIST_REFFERED_AGENT: `${HOST}${VERSION}admin/appointment/listReffered`,
    UPDATE_REFFERED: `${HOST}${VERSION}admin/appointment/updateReffered`,
    CREATE_TRACKER: `${HOST}${VERSION}admin/appointment/createTracker`,
    EDIT_DATA_USER: `${HOST}${VERSION}admin/appointment/edit`,

    // ADMIN - PARAMETER
    LOAD_INSURANCE: `${HOST}${VERSION}admin/parameter/list`,

    // CREATE FORM OBAMA CARE
    CREATE_FORM_OBAMA_CARE: `${HOST}${VERSION}admin/obamacare/create`,

    // PARAMETER
    LOAD_PARAMETER: `${HOST}${VERSION}admin/parameter/list/`,
    LIST_EMAIL_MODULE: `${HOST}${VERSION}admin/parameter/listEmailModule/`,

    // LIST STATUS
    LIST_STATUS: `${HOST}${VERSION}admin/obamacare/listStatusTracker`,
    LIST_STATUS_SUPERVISOR: `${HOST}${VERSION}admin/obamacare/listStatusTrackersupervisor`,

    // SEARCH PATIENTS
    SEARCH_PATIENTS: `${HOST}${VERSION}admin/patients/search`,

    // Status Follow-up
    LIST_TRACKING: `${HOST}${VERSION}admin/obamacare/listTracker`,
    CREATE_TRACKING: `${HOST}${VERSION}admin/obamacare/createTracker`,
    UPDATE_TRACKING: `${HOST}${VERSION}admin/obamacare/updateTracker`,
    UPDATE_FORMOBAMACARE: `${HOST}${VERSION}admin/obamacare/editform`,

    // Upload
    UPLOADEXCELFILE: `${HOST}${VERSION}admin/upload/uploadexcel`,
    LISTPHONEAGENTALL: `${HOST}${VERSION}admin/upload/listPhoneAgentAll`,
    LISTAGENTALL: `${HOST}${VERSION}admin/upload/listAgentAll`,
    LISTALLUSER: `${HOST}${VERSION}admin/upload/listAllUser`,
    UPDATESTATUS: `${HOST}${VERSION}admin/upload/updateStatus`,

    LISTAGENTALLFILTER: `${HOST}${VERSION}admin/upload/listAgentAllFilter`,
    LISTAGENTFILTER: `${HOST}${VERSION}admin/upload/listAgentFilter`,
    LISTAGENTFILTERSTATUS: `${HOST}${VERSION}admin/upload/listAgentFilterStatus`,

    // USERS
    LISTUSERS: `${HOST}${VERSION}admin/users/list`,
    CREATEUSERS: `${HOST}${VERSION}admin/users/create`,
    UPDATEUSERS: `${HOST}${VERSION}admin/users/update`,
    UPDATESTATUSUSERS: `${HOST}${VERSION}admin/users/updateStatus`,
    UPDATEPASSUSERS: `${HOST}${VERSION}admin/users/updatePass`,
    LISTAGENT: `${HOST}${VERSION}admin/users/listAgent`,
    LISTAGENTON: `${HOST}${VERSION}admin/users/listAgentON`,
    LISTAGENTPROFILE: `${HOST}${VERSION}admin/users/listAgentProfile`,
    REPORTLOGIN: `${HOST}${VERSION}admin/users/reportLogin`,

    // CRUD AGENT
    CREATEASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/create`,
    UPDATEASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/update`,
    UPDATESTATUSAGENT: `${HOST}${VERSION}admin/assignedagent/updatestatus`,
    LISTASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/list`,

    // CREATE LIST PHONE CARE CROSS
    CREATELISTCARECROSS: `${HOST}${VERSION}admin/carecross/createlistphone`,
    LISTPHONECARECROSS: `${HOST}${VERSION}admin/carecross/listphone`,

    // REPORTS STATUS PHONE 
    REPORTSTATUSPHONE: `${HOST}${VERSION}admin/obamacare/reports`,
    REPORTSTATUSPHONENOANSWERAGENT: `${HOST}${VERSION}admin/obamacare/reportnumbernoansweragent`,
    DELETEAPPOINTMENT: `${HOST}${VERSION}admin/obamacare/deleteAppointment`,

    // UPDATE STATUS PHONE AGENT
    UPDATESTATUSPHONEAGENT: `${HOST}${VERSION}admin/obamacare/updatestatusphoneagent`,

    // APPOINTMENT SALES
    CREATESALES: `${HOST}${VERSION}admin/obamacare/createsales`,
    CREATESALESPENDING: `${HOST}${VERSION}admin/obamacare/createsalespending`,
    CREATESALESDRAFTAGENT: `${HOST}${VERSION}admin/obamacare/createsalesDraftAgent`,
    UPDATESALES: `${HOST}${VERSION}admin/obamacare/updatesales`,
    UPDATESALESQUALITY: `${HOST}${VERSION}admin/obamacare/updatesalesQuality`,
    UPDATESALESPROCESS: `${HOST}${VERSION}admin/obamacare/updatesalesProcess`,
    UPDATESALESAGENT: `${HOST}${VERSION}admin/obamacare/updatesalesAgent`,
    UPDATESALESDATA: `${HOST}${VERSION}admin/obamacare/updatesalesData`,
    UPDATESALESDATARECOVERY: `${HOST}${VERSION}admin/obamacare/updatesalesDataRecovery`,
    UPDATESALESFILE: `${HOST}${VERSION}admin/obamacare/updateSalesFile`,
    UPDATESALESVERIFYBACKOFFICE: `${HOST}${VERSION}admin/obamacare/updateSalesVerifyBackOffice`,
    UPDATEUPLOADSALESDOCUMENT: `${HOST}${VERSION}admin/obamacare/updateUploadSalesDocument`,
    UPDATESTATUSFINISH: `${HOST}${VERSION}admin/obamacare/updateStatusFinish`,
    UPDATESTATUSFINISHNPN: `${HOST}${VERSION}admin/obamacare/updateStatusFinishNPN`,
    UPDATENPN: `${HOST}${VERSION}admin/obamacare/updateNPN`,
    SEARCHSALESPRE: `${HOST}${VERSION}admin/obamacare/searchsalespre`,
    SEARCHSALESPRENEW: `${HOST}${VERSION}admin/obamacare/searchsalesprenew`,
    SEARCHSALESPRENEWRECOVERY: `${HOST}${VERSION}admin/obamacare/searchsalespreNewRecovery`,
    SEARCHSALESPRENEWTRANSFERUSERID: `${HOST}${VERSION}admin/obamacare/searchsalesprenewTransferUserID`,
    SEARCHSALESPRENEWVERIFY: `${HOST}${VERSION}admin/obamacare/searchsalespreNewVerify`,
    SEARCHSALESDEPENDENT: `${HOST}${VERSION}admin/obamacare/searchsalesdepent`,
    SEARCHSALESPLAN: `${HOST}${VERSION}admin/obamacare/searchsalesplan`,
    SEARCHSALESBENEFICIARIES: `${HOST}${VERSION}admin/obamacare/searchsalesbeneficiaries`,
    SEARCHUPLOADSALESDOCUMENT: `${HOST}${VERSION}admin/obamacare/searchuploadsalesdocument`,
    DELETEUPLOADSALES: `${HOST}${VERSION}admin/obamacare/deleteuploadsales`,
    SEARCHSALESAGENTS: `${HOST}${VERSION}admin/obamacare/searchsalesagents`,
    SEARCHSALESPROCESSOR: `${HOST}${VERSION}admin/obamacare/searchsalesprocessor`,
    SEARCHSALESCUSTOMERMONTH: `${HOST}${VERSION}admin/obamacare/searchsalesCustomerMonth`,
    SEARCHSALESPROCESSORBO: `${HOST}${VERSION}admin/obamacare/searchsalesprocessorBO`,
    SEARCHSALESPROCESSORRECOVERY: `${HOST}${VERSION}admin/obamacare/searchsalesprocessorRecovery`,
    SEARCHSALESOBSERVATION: `${HOST}${VERSION}admin/obamacare/searchsalesobservation`,
    TOTALSALES: `${HOST}${VERSION}admin/obamacare/totalSales`,
    TOTALSALESLASTDAYS: `${HOST}${VERSION}admin/obamacare/totalSalesLastDays`,
    TOTALSALESAGENTS: `${HOST}${VERSION}admin/obamacare/totalSalesAgents`,
    TOTALSALESAGENTSPLANDAY: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsPlanDay`,
    TOTALSALESAGENTSPLANMONTH: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsPlanMonth`,

    TOTALSALESTRANSFER: `${HOST}${VERSION}admin/obamacare/totalSalesTransfer`,

    TOTALSALESCOMPANYDAY: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyDay`,
    TOTALSALESCOMPANYMONTH: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyMonth`,
    TOTALSALESCOMPANYYEAR: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyYear`,

    TOTALSALESAGENTSUSER: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsUser`,
    TOTALSALESMONTHUSER: `${HOST}${VERSION}admin/obamacare/totalSalesMonthUser`,
    TOTALSALESMONTHLASTVSCURRENT: `${HOST}${VERSION}admin/obamacare/totalSalesMonthLastVsCurrent`,
    TOTALSALESFORPLAN: `${HOST}${VERSION}admin/obamacare/totalSalesForPlan`,
    LISTYEARS: `${HOST}${VERSION}admin/obamacare/listYears`,
    LISTGOALS: `${HOST}${VERSION}admin/obamacare/listGoals`,
    CREATEGOALS: `${HOST}${VERSION}admin/obamacare/createGoals`,
    EDITGOALS: `${HOST}${VERSION}admin/obamacare/editGoals`,
    CREATEGOALPREMIUMYEAR: `${HOST}${VERSION}admin/obamacare/createGoalPremiumYear`,
    SEARCHNOTEPAD: `${HOST}${VERSION}admin/obamacare/searchNotePad`,
    SAVENOTEPAD: `${HOST}${VERSION}admin/obamacare/saveNotePad`,
    SAVENOTECOLUMNS: `${HOST}${VERSION}admin/obamacare/saveNotecolumns`,
    SEARCHNOTECOLUMNS: `${HOST}${VERSION}admin/obamacare/searchNoteColumns`,
    SEARCHGOALPREMIUMYEAR: `${HOST}${VERSION}admin/obamacare/searchGoalPremiumYear`,
    LISTSALESSUPERVISOR: `${HOST}${VERSION}admin/obamacare/listSalesSupervisor`,
    LISTSALESBACKOFFICE: `${HOST}${VERSION}admin/obamacare/listSalesBackOffice`,
    VERIFYNUMBERPLAN: `${HOST}${VERSION}admin/obamacare/verifyNumber`,
    EXPORTFILESXLXS: `${HOST}${VERSION}admin/obamacare/exportFileXLXS`,
    EXPORTFILESXLXSPROCESSOR: `${HOST}${VERSION}admin/obamacare/exportFileXLXSProcessor`,
    EXPORTFILESXLXSQUALITY: `${HOST}${VERSION}admin/obamacare/exportFileXLXSQuality`,
    DELETEFILESXLXS: `${HOST}${VERSION}admin/obamacare/deleteFileXLXS`,
    SEARCHSALESQUALITY: `${HOST}${VERSION}admin/obamacare/searchsalesquality`,
    SEARCHSALESQUALITYTRANSFER: `${HOST}${VERSION}admin/obamacare/searchsalesqualityTransfer`,
    SEARCHVERIFYMONTH: `${HOST}${VERSION}admin/obamacare/searchVerifyMonth`,
    INSERTVERIFYMONTH: `${HOST}${VERSION}admin/obamacare/insertVerifyMonth`,

    LISTPRENEWAPPOINTMENTSALES: `${HOST}${VERSION}admin/obamacare/listprenewappointmentsales`,
    LISTAPPOINTMENTSALESDATE: `${HOST}${VERSION}admin/obamacare/listappointmentsalesdate`,
    SEARCHAPPOINTMENTSALES: `${HOST}${VERSION}admin/obamacare/searchappointmentsales`,
    SEACRHSALES: `${HOST}${VERSION}admin/obamacare/searchsales`,
    UPDATEFORMSALES: `${HOST}${VERSION}admin/obamacare/updateformsales`,
    DELETESALES: `${HOST}${VERSION}admin/obamacare/deletesales`,
    UPDATESTATUSSALES: `${HOST}${VERSION}admin/obamacare/updateStatusSales`,
    CREATELOGCMSSALES: `${HOST}${VERSION}admin/obamacare/createLogCMSSales`,

    // FORM SUPLEMENTAL
    UPLOADFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/uploadformsuplemental`,
    LISTFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/listformsuplemental`,
    UPDATEFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/updateformsuplemental`,
    DELETESUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/deletesuplemental`,

    // AGENCY
    LISTAGENCY: `${HOST}${VERSION}admin/agency/listagency`,
    CREATEAGENCY: `${HOST}${VERSION}admin/agency/createagency`,
    UPDATEAGENCY: `${HOST}${VERSION}admin/agency/updateagency`,

    EXPORTDATAEXCELFILTER: `${HOST}${VERSION}admin/obamacare/exportDataExcelFilters`,
    LISTSALESBACKOFFICEFILTER: `${HOST}${VERSION}admin/obamacare/listSalesBackOfficeFilter`,

    TRANSFERSALES: `${HOST}${VERSION}admin/obamacare/transferSales`,
    LISTHOSTTRANSFERS: `${HOST}${VERSION}admin/obamacare/listHostTransfers`,
    SEARCHSALESPREOTHER: `${HOST}${VERSION}admin/obamacare/searchsalespreOther`,
    CREATEMEMBERID: `${HOST}${VERSION}admin/obamacare/createMemberID`,


    UPDATESALESDATAOTHER: `${HOST}${VERSION}admin/obamacare/updatesalesDataOther`,//Actualizar la data de la venta transferida

    LISTROWSALEMONTH: `${HOST}${VERSION}admin/users/listRowSaleMonth`,
    CREATEROWSALEMONTH: `${HOST}${VERSION}admin/users/createRowSaleMonth`,
    DELETEROWSALEMONTH: `${HOST}${VERSION}admin/users/deleteRowSaleMonth`,

    SEARCHSALESCUSTOMERMONTHFILTER: `${HOST}${VERSION}admin/obamacare/searchsalesCustomerMonthFilter`,
    SEARCHNPNFORSTATE: `${HOST}${VERSION}admin/obamacare/searchNPNForState`,
}

export default APP_URL